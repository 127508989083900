import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import {
  FaClock,
  FaPlus,
  FaPuzzlePiece,
  FaSchool,
  FaSlidersH,
  FaUsers,
  FaSmileBeam,
  FaPeopleCarry,
  FaGraduationCap,
  FaGift,
  FaTh,
} from "react-icons/fa";
import ResponsiveEmbed from "react-responsive-embed";
import { BsFillAlarmFill,
BsFillBriefcaseFill,
BsFillGiftFill,
BsEmojiLaughingFill,
BsToggles,
BsGridFill,
 } from "react-icons/bs";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import Container from "../components/container";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import { Link } from "gatsby";

export const query = graphql`
  {
    rekrutacja: file(relativePath: { eq: "dolaczdonas.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    sse: file(relativePath: { eq: "doradca-klienta.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
        bdm: file(relativePath: { eq: "business-development-manager.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    pm: file(relativePath: { eq: "ogloszenie_pm.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    hero: file(relativePath: { eq: "marketing-hero-ogloszenie.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    developer: file(relativePath: { eq: "junior-developer.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    fd: file(relativePath: { eq: "fd.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    jpm: file(relativePath: { eq: "junior-project-manager.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
        administracja: file(relativePath: { eq: "administracja.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
  }
`;

const Kariera = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Kariera w Questy"
      headline="Nadaj rozpędu swojej karierze"
    >
      <SEO
        title="Kariera w Questy"
        description="Kariera w Questy ✅ Wspólne tworzenie projektów • Rozwiązywanie problemów klientów • Świętowanie sukcesów – razem"
      />
        <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      > Kim jesteśmy? </h2>
      <p>
       Jesteśmy producentem i dostawcą oprogramowania działającym na polskim rynku już
        od 2002 roku. Pracujemy zarówno dla dużych, rozpoznawalnych firm, jak&nbsp;i&nbsp;dla 
        mniejszych, dynamicznie rozwijających się przedsiębiorstw. Nasz zespół składa się z ponad 50
        specjalistów, których łączy wspólna pasja – technologie informacyjne. </p>
        <p>Fundamentem naszej codziennej działalności jest<strong> dostarczanie rozwiązań informatycznych, które
        efektywnie wspierają rozwój biznesu</strong>. Naszą ambicją i&nbsp;długofalową wizją – zostanie najlepszym 
        dostawcą systemów IT wykorzystywanych w&nbsp;organizacjach na całym świecie.
        </p>
        <h2  style={{
          paddingTop: 20,
          paddingBottom: 15,
        }}>Wierzymy, że:</h2>
        <p><ul>
        <li>tworząc rozwiązania wysokiej jakości, przyczyniamy się do rozwoju naszych klientów,</li>
        <li>miarą naszego sukcesu jest sukces klienta i&nbsp;z&nbsp;zaangażowaniem walczymy o jego osiągnięcie,</li>
        <li>nasi pracownicy i klienci są partnerami, którzy wspólnie budują długoterminową przyszłość,</li>
        <li>dzięki współpracy osiągniemy więcej niż każdy z&nbsp;nas działając osobno,</li>
        <li>inicjatywa i zaangażowanie są podstawą rozwoju i&nbsp;zasługują na uznanie,</li>
        <li>ciągłe doskonalenie metod działania oraz&nbsp;produktu jest dla nas czymś oczywistym.</li></ul></p>
      <h2
        style={{
          paddingBottom: 20,
          paddingTop: 30,
        }}
      >
        Tworzymy zgrany zespół
      </h2>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              To, co motywuje nas do codziennego działania to współpraca. <br />
              Każdego dnia wspólnie tworzymy projekty, rozwiązujemy problemy
              naszych klientów, świętujemy nasze sukcesy, świetnie się przy tym
              wszystkim bawiąc.
              <br />
              <strong>
                {" "}
                Przecież w dobrej atmosferze obowiązki stają się przyjemnością,
                prawda?
              </strong>
            </p>
          </div>
          <div className="text-block">
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/lLKbdB1I0xo"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>

      <h2
        style={{
          paddingBottom: 30,
          paddingTop: 30,
          textAlign: "center",
        }}
      >
        {" "}
        Dlaczego warto z nami pracować?{" "}
      </h2>
      <br />

      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<BsFillBriefcaseFill size="60px" />}
          title="Już od 2002 roku budujemy doświadczenie w&nbsp;biznesie"
        />
        <BenefitBlock
          icon={<BsEmojiLaughingFill size="60px" />}
          title="Ponad 50 zapalonych fanów technologii na&nbsp;pokładzie"
        />
        <BenefitBlock
          icon={<BsGridFill size="60px" />}
          title="Realizujemy projekty z&nbsp;różnych dziedzin"
        />
      </BenefitBlockWrapper>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaGraduationCap size="60px" />}
          title="Dzielimy się wiedzą i&nbsp;doświadczeniem"
        />
        <BenefitBlock
          icon={<BsToggles size="60px" />}
          title="Jesteśmy elastyczni i&nbsp;do każdego podchodzimy indywidualnie"
        />
        <BenefitBlock
          icon={<BsFillGiftFill size="60px" />}
          title="Oferujemy dodatkowe benefity"
        />
      </BenefitBlockWrapper>
            <Container>

      <h2
        style={{
          paddingTop: 30,
          textAlign: "center",
        }}
      >

        Przekonaliśmy Cię?
      </h2>
      <p
        style={{
          paddingBottom: 20,
          textAlign: "center",
        }}
      >
        Zobacz, jak do nas dołączyć.{" "}
      </p>
      <GatsbyImage
        image={data.rekrutacja.childImageSharp.gatsbyImageData}
        style={{
          maxWidth: 1200,
          margin: "0 auto",
          marginTop: 30,
        }}
        alt="rekrutacja"
      />
      </Container>
      <Container>
      <center>
        <br />
        <h2>Czekamy na Ciebie!</h2>{" "}
      </center>
      </Container>
    </FunctionPageLayout>
  );
};

export default Kariera;
